<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-3">
            <CCol md="12" class="p-0">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
                    <h2 class="text-center text-white middle-center"><b>Informasi Kontak</b></h2>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white text-center">
                            <h4 class="my-0 ">
                                <b>Kontak/Konfirmasi Ekspor Impor </b>
                            </h4>
                            <b> Timeline : Verifikasi Administrasi, Notifikasi SIP</b>
                        </CCardHeader>
                        <CCardBody>
                            <a href="mailto:pvt@pertanian.go.id" target="_blank">pvt@pertanian.go.id</a><br>
                            021 7883969, sms center (via Humas) : 08121068805<br>
                            Channel Info Telegram (informal) : <a href="https://t.me/infoeximhorti" target="_blank">https://t.me/infoeximhorti</a><br>
                            Jl Harsono RM No 3, Ragunan, PADU SATU, gedung B lantai 1 membawa Rapid Antigen. Ditemui petugas piket. Jam buka 09.00-14.30. 12.00-13.00 : istirahat.
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white text-center">
                            <h4 class="my-0 ">
                                <b>Kontak/Konfirmasi Ekspor Impor </b>
                            </h4>
                            <b> Timeline : Verifikasi teknis, dan Persetujuan Dirjen</b>
                        </CCardHeader>
                        <CCardBody>
                            <a href="mailto:perizinanhorti@yahoo.com" target="_blank">perizinanhorti@yahoo.com</a><br>
                            call centre Ditjen Hortikultura  0859 6064 6618 atau 0812 1952 6440 <br>
                            JL. AUP No. 3, Pasar Minggu
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white text-center">
                            <h4 class="my-0 ">
                                <b>Kontak/Konfirmasi Teknis Impor Barantan Pusat, Pusat Karantina. Tumbuhan dan Keamanan Hayati Nabati </b>
                            </h4>
                            <b>Timeline : Verifikasi Badan Karantina, Rekom/AROPT</b>
                        </CCardHeader>
                        <CCardBody>
                            <a href="mailto:karantina.tumbuhanbenih@pertanian.go.id" target="_blank">karantina.tumbuhanbenih@pertanian.go.id</a><br>
                            call center Barantan 021 7816482 <br>
                            Jl. Harsono RM no 3, Ragunan, Gedung E lantai 5
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
    const null_array = [];
    export default {
        name: "InformasiKontak",
        data() {
            return {
                accordion: 0,
                tipe_faq: null_array
            };
        },
        methods: {
        },
        created() {
            
        },
    };
</script>